html {
	height: 100%;

	body,
	#__next {
		height: 100%;
		margin: 0;
		-webkit-font-smoothing: antialiased;
		font-family: 'Poppins', sans-serif;

		button, input, textarea {
			font-family: 'Poppins', sans-serif;
		}

		@media (prefers-reduced-motion: reduce) {
			* {
				animation: none;
				transition: none;
			}
		}
	}
}
